<template>
    <div>
<!--        <common-header>個人信息</common-header>-->
        <div class="m-wrapper03">
        <ul class="m-setting01">
            <li class="setting-con01">
                <em class="c-rose required-em">*</em>
                <span class="setting-lab01">姓名</span>
                <input type="text" placeholder="请输入真实姓名" v-model="personalObj.name" class="setting-ipt01">
            </li>
            <li class="setting-con01">
                <em class="c-rose required-em">*</em>
                <span class="setting-lab01">性別</span>
                <input @click="chooseSex" readonly type="text" placeholder="请选择性别" v-model="sexObj[personalObj.sex]" class="setting-ipt01">
            </li>
            <li class="setting-con01">
                <em class="c-rose required-em">*</em>
                <span class="setting-lab01">出生年月</span>
                <input @click="chooseDate" type="text" placeholder="请选择出生日期" v-model="personalObj.birthday" readonly class="setting-ipt01 setting-ipt05">
            </li>
            <li class="setting-con01">
                <em class="c-rose required-em">*</em>
                <span class="setting-lab01">手機號碼</span>
                <input type="text" readonly :value="personalObj.telephone" class="setting-ipt01">
            </li>
            <li class="setting-con01">
                <!-- <em class="c-rose required-em">*</em> -->
                <span class="setting-lab01">台胞證號</span>
                <input type="text" placeholder="請填寫台胞證號" v-model="personalObj.taiwanCardNo" class="setting-ipt01">
            </li>
            <li class="setting-con01">
                <!-- <em class="c-rose required-em">*</em> -->
                <span class="setting-lab01">健保證號</span>
                <input type="text" placeholder="請輸入健保證號" v-model="personalObj.healthCardNo" class="setting-ipt01">
            </li>
            <li class="setting-con01 setting-address">
                <span class="setting-lab01">台灣現住地</span>
                <!-- <input @click="modifyTaiAddress" type="text" placeholder="请添加地址" readonly v-model="personalObj.taiAddress" class="setting-ipt01 setting-ipt05"> -->
                <textarea placeholder="請輸入住址" @input="changeTextarea($event, 1)" ref="addressRef" v-model="personalObj.addressForTaiwan" class="setting-ipt01 setting-textarea"></textarea>
            </li>
            <li class="setting-con01 setting-address">
                <span class="setting-lab01">大陸現住地</span>
                <!-- <input type="text" placeholder="请添加地址" v-model="personalObj.nowAddress" class="setting-ipt01"> -->
                <textarea placeholder="請輸入住址" @input="changeTextarea($event, 2)" ref="nowAddressRef" v-model="personalObj.address" class="setting-ipt01 setting-textarea"></textarea>
            </li>
            <li class="setting-con01">
                <span class="setting-lab01">郵箱</span>
                <input type="text" placeholder="请添加邮箱" v-model="personalObj.email" class="setting-ipt01">
            </li>
            <!-- <li class="setting-con01">
                <span class="setting-lab01">密碼</span>
                <input @click="toModifyPassword" type="text" value="修改" readonly class="setting-ipt01 setting-ipt05">
            </li> -->
            <!-- <li class="setting-con01">
                <span class="setting-lab01">医院就诊卡</span>
                <input @click="chooseCard" type="text" placeholder="请添加就诊卡" v-model="realNameInfo.cardNumber" readonly class="setting-ipt01 setting-ipt05">
            </li> -->
        </ul>
        <!-- 选择日期 -->
        <van-popup position="bottom" v-model="showChooseDate" class="date-container">
            <van-datetime-picker
                v-model="currentDate"
                type="date"
                title="選擇年月日"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="handleConfirm"
                @cancel="handleCancel"
            />
        </van-popup>
        <!-- 选择性别 -->
        <van-popup position="bottom" v-model="showChooseSex" class="sex-container">
            <ul>
                <li @click="modifySex(sex.code)" v-for="sex in sexList" :key="sex.id" class="item" :class="personalObj['sex'] == sex.code ? 'active' : ''">{{sex.val}}</li>
            </ul>
        </van-popup>
    </div>
    <a @click="save">
        <div class="m-patient-but">保存</div>
    </a>
    </div>
</template>
<script>
import store from '@/store'
import {mapState} from 'vuex'
import {getDate, validateEmail} from '../../utils/tools'
import {sexList, sexObj} from '@/constant/constant'
// import CommonHeader from '@/components/header'
export default ({
    store,
    data() {
        return {
            sexList,
            sexObj,
            minDate: new Date(1950, 0, 1),
            maxDate: new Date(2025, 10, 1),
            currentDate: new Date(2021, 1, 17),
            showChooseDate: false,
            showChooseSex: false,
            phoneNum: '',
            personalObj: {}
        }
    },
    components: {
        // CommonHeader
    },
    computed: {
        ...mapState({
            // personalObj: state => state.mineInfo.personalObj,
            realNameInfo: state => state.layout.realNameInfo
        })
    },
    mounted() {
        // 页面初始获取实名信息
        this.$store.dispatch('getRealNameInfo').then(res => {
            this.personalObj = res.data
        })
        this.phoneNum = localStorage.getItem('phone')
        this.$refs.addressRef.style.height = this.$refs.addressRef.scrollHeight + 'px'
    },
    methods: {
        // toModifyPassword() {
        //     // 跳转到修改密码界面
        //     this.$router.push('/modifyPassword')
        // },
        chooseCard() {
            this.$router.push('/manageCard')
        },
        modifySex(val) {
            this.showChooseSex = false;
            this.personalObj['sex'] = val
        },
        changeTextarea(e, target) {
            if(target == 1) {
                this.$store.commit('modifyPersonalObj', {
                    taiAddress: e.target.value
                })
                this.$refs.addressRef.style.height = this.$refs.addressRef.scrollHeight + 'px'
            }else {
                this.$store.commit('modifyPersonalObj', {
                    nowAddress: e.target.value
                })
                this.$refs.nowAddressRef.style.height = this.$refs.nowAddressRef.scrollHeight + 'px'
            }
        },
        chooseSex() {
            this.showChooseSex = true;
        },
        chooseDate() {
            // 选择出生日期
            this.showChooseDate = true;
        },
        save() {
            const {name, birthday, sex, email} = this.personalObj
            // 对四个必填项目做校验
            if(!name || !birthday || !sex) {
                this.$toast({
                    message: '请选择/填写姓名，出生年月，性别',
                    position: 'top'
                })
                return
            }
            // 如果有输入邮箱信息 对邮箱格式做校验
            if(!!email && !validateEmail(email)) {
                this.$toast({
                    message: '请输入正确格式的邮箱'
                })
                return
            }
            // 保存操作
            this.$store.dispatch('setRealNameInfo', this.personalObj).then(() => {
                this.$router.push('/mine')
            })
        },
        modifyTaiAddress() {
            // 修改台湾现住址
            this.$router.push('/modifyAddress')
        },
        handleCancel() {
            // 取消时间选择
            this.showChooseDate = false
        },
        handleConfirm() {

            // 确认更改出生年月时间
            let date = getDate(this.currentDate.getTime(), '-')
            // this.$store.commit('modifyRealNameInfo', {
            //     date
            // })
            console.log('date', date)
            this.personalObj['birthday'] = date
            this.showChooseDate = false
        }
    }
})
</script>
<style lang="scss" scoped>
.setting-lab01 {
    text-align: left;
}

.m-wrapper03 {
    padding-top: 0px;
}

.setting-ipt01 {
    padding-left: 20px;
    box-sizing: border-box;
    text-align: right;
}

.m-patient-but {
    background: #00C2C1;
}

.date-container {
    position: fixed;
    width: 100%;
    bottom: 0;
}

.setting-textarea {
    border: none;
    line-height: 1.5;
    padding: 16px;
    padding-right: 0;
}

.setting-address {
    display: flex;
    align-items: flex-start;

    .setting-lab01 {
        padding-top: 16px;
    }
}

.sex-container {
    .item {
        height: 50px;
        line-height: 50px;
        &:nth-child(1) {
            border-bottom: 1px solid #efefef;
        }

        &.active {
            color: #00C2C3;
        }
    }
}
</style>
